.font-family-1 {
  font-family: 'Anton', sans-serif;
}

// Button
.btn-info {
  @include button-variant($info, $info, $white, shade-color($info, 15%), shade-color($info, 20%), $white);
}

.white-transparent {
  background-color: rgba(255, 255, 255, 0.8);
}

.navbar-light .navbar-nav {
  .show > .nav-link, .nav-link.active {
    background-color: $info;
    border-radius: 5px;
    box-shadow: 0 .125rem .25rem rgba($black, .075);
  }
}

.page-heading {
  font-size: 8rem;
  line-height: .8;
  text-transform: uppercase;
}

.parallax {
  background-image: url("/images/bg-parallax.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-separator-1 {
  background: url('/images/border-01-01.svg') repeat-x center top;
}

.build.carousel.slide {
  .carousel-inner {
    height: 400px;
    overflow-y: hidden;
  }
}

@include media-breakpoint-down(xl) {
  .navbar-nav .nav-link {
    font-size: .8rem;
  }
}

@include media-breakpoint-down(lg) {
  .navbar-nav .nav-link {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-down(sm) {
  .page-heading {
    font-size: 5rem;
  }
  .form-floating > label, .input-group-lg > .form-select {
    font-size: .9rem;
  }
}