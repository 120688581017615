// Body
$blue:    #175E7C !default;
$cyan:    #28A49D !default;
$black:   #000000 !default;
$white:   #ffffff !default;

$primary:       $blue !default;
$info:          $cyan !default;
$light:       #f8fafc !default;

$body-bg: $light;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1.2rem;
$line-height-base: 1.6;

// Navbar
$navbar-brand-margin-end: 3rem;
$navbar-nav-link-padding-x: 2rem;
$navbar-light-color: $primary;
$navbar-light-hover-color: $info;
$navbar-light-active-color: $white;

// Form
$form-label-font-size: 1rem;
$form-label-color: $primary;
$form-label-margin-bottom: .2rem;
$form-label-font-weight: bold;
$form-check-label-color: $primary;
$form-check-label-cursor: pointer;
$form-floating-input-padding-t: 2.4rem